import './ContentResourceInfo.scss';
import { getProvidersProps } from 'dhub-config';
import PropTypes from 'prop-types';
import React from 'react';

const providersUrls = getProvidersProps(['urls']);

/**
 * This component renders a container with information of a resource.
 */
function ContentResourceInfo(props) { // eslint-disable-line max-lines-per-function
    const updatedDiff = Date.now() - Date.parse(props.resource.updated);
    let updatedColor = null;

    if (updatedDiff < 86400000) { // 86400000 ms = 1 day
        updatedColor = 'colorRed';
    } else if (updatedDiff < 604800000) { // 604800000 ms = 1 week
        updatedColor = 'colorYellow';
    } else if (updatedDiff < 2592000000) { // 2592000000 ms = 1 month
        updatedColor = 'colorGreen';
    }

    return (
        <div className='contentResourceInfo'>
            <p>{props.resource._id}</p>

            <div>
                <h2>{'Title'}</h2>

                <p>{props.resource.name}</p>
            </div>

            <div>
                <h2>{'Origin'}</h2>

                <p>{props.resource.origin}</p>
            </div>

            <div>
                <h2>{'Type'}</h2>

                <p>{props.resource.type === 'movie' ? 'Movie' : 'Series'}</p>
            </div>

            <div>
                <h2>{'URL'}</h2>

                <a href={`${providersUrls[props.resource.origin][0]}${props.resource.url}`}
                    target='_blank' rel='noopener noreferrer'
                >
                    {`${providersUrls[props.resource.origin][0]}${props.resource.url}`}
                </a>
            </div>

            <div>
                <h2>{'Updated'}</h2>

                <p className={updatedColor}>{new Date(props.resource.updated).toGMTString()}</p>
            </div>

            <div>
                <h2>{'Deletion Alert'}</h2>

                <p className={props.resource.deletionAlert && 'colorRed'}>
                    {props.resource.deletionAlert ? new Date(props.resource.deletionAlert).toGMTString() : '-'}
                </p>
            </div>

            <div>
                <h2>{'Related Auto'}</h2>

                <p>{props.resource.relatedAutoCount}</p>
            </div>

            <div>
                <h2>{'Related Manual'}</h2>

                <p className={props.resource.relatedManualCount && 'colorYellow'}>
                    {props.resource.relatedManualCount}
                </p>
            </div>
        </div>
    );
}

ContentResourceInfo.propTypes = {
    resource: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        deletionAlert: PropTypes.string,
        name: PropTypes.string.isRequired,
        origin: PropTypes.string.isRequired,
        relatedAutoCount: PropTypes.number.isRequired,
        relatedManualCount: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
        updated: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }).isRequired
};

export default ContentResourceInfo;