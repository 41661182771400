import './ContentResource.scss';
import ContentResourceInfo from './ContentResourceInfo';
import ContentResourceMedia from './ContentResourceMedia';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * This component renders a container with an instance of ContentResourceInfo (response info) and multiple ContentResourceMedia
 * (resource media) and allows to confirm and send to the server the selected media.
 */
class ContentResource extends React.Component {
    static propTypes = {
        authToken: PropTypes.string.isRequired,
        correctMedia: PropTypes.arrayOf(PropTypes.number).isRequired,
        media: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
        resource: PropTypes.shape({
            _id: PropTypes.string.isRequired,
            relatedAutoCount: PropTypes.number.isRequired,
            relatedManualCount: PropTypes.number.isRequired,
            type: PropTypes.string.isRequired
        }).isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            correctMedia: new Set(this.props.correctMedia),
            error: null,
            result: null
        };

        this.handleMediaClick = this.handleMediaClick.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.sortMedia = this.sortMedia.bind(this);
        this.shouldDisableButton = this.shouldDisableButton.bind(this);
    }


    handleMediaClick(event) {
        const { correctMedia } = this.state;
        const value = parseInt(event.target.value, 10);

        if (correctMedia.has(value)) {
            correctMedia.delete(value);
        } else {
            correctMedia.add(value);
        }

        this.setState({ correctMedia });
    }

    handleConfirm() {
        const msg = 'This action will remove all the related auto media and set the selected as the new related manual media.\n'
            + 'Are you sure you want to continue?\n\n'
            + `Resource ID: ${this.props.resource._id}\n\n`
            + `Selected media: ${[...this.state.correctMedia].join(', ')}\n\n`
            + `Transition: [${this.props.resource.relatedAutoCount}, ${this.props.resource.relatedManualCount}] =>`
            + ` [0, ${this.state.correctMedia.size}] ([auto, manual])`;

        if (window.confirm(msg)) { // eslint-disable-line no-alert
            this.setState({ error: null });

            fetch(`${DOWNLOAD_HUB_REDUCER_SERVER_URL}${DOWNLOAD_HUB_REDUCER_SERVER_API.resources}`, {
                body: JSON.stringify({
                    id: this.props.resource._id,
                    related: [...this.state.correctMedia]
                }),
                headers: {
                    'Authorization': `Bearer ${this.props.authToken}`,
                    'Content-Type': 'application/json'
                },
                method: 'PUT',
                timeout: DOWNLOAD_HUB_REDUCER_REQ_TIMEOUT
            }).then((res) => {
                if (res.status === 200) {
                    return this.setState((prevState) => ({
                        result: `Removed all the related auto media and set [${[...prevState.correctMedia].join(', ')}]`
                            + ' as the new related manual media.'
                    }));
                }

                return this.setState({ error: `Failed to update the resource. Status code: ${res.status}` });
            }).catch((err) => this.setState({ error: JSON.stringify(err) }));
        }
    }

    sortMedia() {
        const correctMedia = [...this.state.correctMedia];

        return this.props.media.sort((a, b) => {
            if (correctMedia.includes(b.id)) {
                return correctMedia.includes(a.id) ? b.popularity - a.popularity : 1;
            } else if (correctMedia.includes(a.id)) {
                return 0;
            }

            return b.popularity - a.popularity;
        });
    }

    shouldDisableButton() {
        if (!this.state.correctMedia.size) {
            return true;
        } else if (this.state.correctMedia.size === this.props.correctMedia.length) {
            return this.props.correctMedia.reduce((acc, curr) => acc && this.state.correctMedia.has(curr), true);
        }

        return false;
    }


    render() {
        return this.state.result
            ? (
                <div className='contentResource'>
                    <ContentResourceInfo resource={this.props.resource} />

                    <div className='contentResourceRes'>
                        <p>{this.state.result}</p>
                    </div>
                </div>
            )
            : (
                <div className='contentResource'>
                    <ContentResourceInfo resource={this.props.resource} />

                    {
                        this.state.error && (
                            <div className='contentResourceErr'>
                                <p className='colorRed'>{this.state.error}</p>
                            </div>
                        )
                    }

                    <div className='contentResourceReq'>
                        <h2>{'Correct Media'}</h2>

                        <p>{[...this.state.correctMedia].join(', ') || '-'}</p>

                        <button type='button' disabled={this.shouldDisableButton()} onClick={this.handleConfirm}>{'Confirm'}</button>
                    </div>

                    <div>
                        {this.sortMedia().map((media) => (
                            <ContentResourceMedia key={media.id} media={media} type={this.props.resource.type}
                                checked={this.state.correctMedia.has(media.id)} onMediaClick={this.handleMediaClick} />
                        ))}
                    </div>
                </div>
            );
    }
}

export default ContentResource;